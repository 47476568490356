import React from "react";
import "./Feed.css";
import { slice, concat } from "lodash";
import parse from "html-react-parser";
let Parser = require("rss-parser");
class Feed extends React.Component {
  constructor(props) {
    super(props);
    this.limit = 3;
    this.data = [];
    this.state = {
      showMore: true,
      list: [],
      index: this.limit,
    };
  }

  loadMoresetup = () => {
    this.data = this.state.items ? this.state.items : null;
    this.setState({
      list: slice(this.data, 0, this.state.index),
      showMore: this.limit < this.data.length,
    });
  };

  componentDidMount() {
    this.parseRss();
  }

  getDate = (date) => {
    date = new Date(date);
    return date.toDateString();
  };

  async parseRss() {
    const CORS_PROXY = "https://cors-anywhere-react.herokuapp.com/";
    let parser = new Parser({
      customFields: {
        item: ["media:thumbnail"],
      },
    });
    let feed = await parser.parseURL(
      CORS_PROXY + "https://www.billboard.com/feed/"
    );
    this.setState({
      items: feed.items,
    });
    this.loadMoresetup();
  }

  loadMore = () => {
    const newIndex = this.state.index + this.limit;
    const newShowMore = newIndex < this.data.length;
    const newList = concat(
      this.state.list,
      slice(this.data, this.state.index, newIndex)
    );
    this.setState({
      index: newIndex,
      list: newList,
      showMore: newShowMore,
    });
  };
  render() {
    return (
      <div className="container">
        <div className="card-columns">
          {this.state.list
            ? this.state.list.map((item) => {
                return (
                  <div key={item.title} className="card news-item">
                    <a href={item.link}>
                      {/* <img
                        className="card-img-top"
                        src={item["media:thumbnail"].$.url}
                        alt="Card image cap"
                      /> */}
                      <div className="card-body">
                        <h5 className="card-title">{item.title}</h5>
                        <p className="card-text">{parse(item.content)}</p>
                        <p className="card-text">
                          <small className="text-muted">
                            <i className="fa fa-user"></i>&nbsp;&nbsp;
                            {item.creator}&nbsp;&nbsp;
                            <i className="fa fa-calendar"></i>&nbsp;&nbsp;
                            {this.getDate(item.pubDate)}
                          </small>
                        </p>
                      </div>
                    </a>
                  </div>
                );
              })
            : null}
        </div>
        {this.state.showMore && (
          <button className="btn btn-light btn-lg" onClick={this.loadMore}>
            {" "}
            Load More{" "}
          </button>
        )}
      </div>
    );
  }
}

export default Feed;
