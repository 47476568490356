import React from "react";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import * as yup from "yup";
import { sendEmail } from "../../store/actions";
import Recaptcha from "react-recaptcha";
import Swal from "sweetalert2";

const schema = yup.object({
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  email: yup.string().required(),
  mobile: yup.string().required(),
  message: yup.string().required(),
  recaptcha: yup.string().required(),
});
class Contact extends React.Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }
  onFormSubmit = (values) => {
    var form_data = new FormData();
    for (var key in values) {
      form_data.append(key, values[key]);
    }
    this.props.sendEmail(form_data);
  };

  render() {
    return (
      <section id="Contact" className="content-section">
        <div className="container">
          <div className="block-heading">
            <h1>Contact Us</h1>
          </div>
          {/* <div className="lead">
            <p>Meet</p>
          </div> */}
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="contact-wrapper">
                <div className="address-block">
                  <h3 className="add-title">Headquaters</h3>
                  <div className="c-detail">
                    <span className="c-icon">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                    </span>
                    <span className="c-info">&nbsp;Miami, FL USA</span>
                  </div>
                  <div className="c-detail">
                    <span className="c-icon">
                      <i className="fa fa-phone" aria-hidden="true"></i>
                    </span>
                    <span className="c-info">+xxx xxxx xxx</span>
                  </div>
                  <div className="c-detail">
                    <span className="c-icon">
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                    </span>
                    <span className="c-info">getsigned@topdotmusic.com</span>
                  </div>
                </div>
                {/* <div className="address-block">
                  <h3 className="add-title">Branch</h3>
                  <div className="c-detail">
                    <span className="c-icon">
                      <i className="fa fa-map-marker" aria-hidden="true"></i>
                    </span>
                    <span className="c-info">
                      &nbsp;98 Berry - Cheyenne, CO 80810
                    </span>
                  </div>
                  <div className="c-detail">
                    <span className="c-icon">
                      <i className="fa fa-phone" aria-hidden="true"></i>
                    </span>
                    <span className="c-info">+123 4567 8987</span>
                  </div>
                  <div className="c-detail">
                    <span className="c-icon">
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                    </span>
                    <span className="c-info">email@yourdomain.com</span>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="form-wrap">
                <Formik
                  validationSchema={schema}
                  onSubmit={this.onFormSubmit}
                  initialValues={{
                    firstname: "",
                    lastname: "",
                    email: "",
                    mobile: "",
                    message: "",
                    recaptcha: "",
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                    setFieldValue,
                    touched,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <div className="fname floating-label">
                        <input
                          type="text"
                          className="floating-input"
                          name="firstname"
                          value={values.firstname}
                          onChange={handleChange}
                        />
                        <label>First Name</label>
                        {errors.firstname && touched.firstname && (
                          <p style={{ color: "red" }}>{errors.firstname}</p>
                        )}
                      </div>
                      <div className="fname floating-label">
                        <input
                          type="text"
                          className="floating-input"
                          name="lastname"
                          value={values.lastname}
                          onChange={handleChange}
                        />
                        <label>Last Name</label>
                        {errors.lastname && touched.lastname && (
                          <p style={{ color: "red" }}>{errors.lastname}</p>
                        )}
                      </div>
                      <div className="email floating-label">
                        <input
                          type="email"
                          className="floating-input"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                        <label>Email</label>
                        {errors.email && touched.email && (
                          <p style={{ color: "red" }}>{errors.email}</p>
                        )}
                      </div>
                      <div className="contact floating-label">
                        <input
                          type="tel"
                          className="floating-input"
                          name="mobile"
                          value={values.mobile}
                          onChange={handleChange}
                        />
                        <label>Mobile</label>
                        {errors.mobile && touched.mobile && (
                          <p style={{ color: "red" }}>{errors.mobile}</p>
                        )}
                      </div>
                      <div className="company floating-label">
                        <textarea
                          type="text"
                          className="floating-input"
                          name="message"
                          onChange={handleChange}
                          value={values.message}
                        >
                          {values.message}
                        </textarea>

                        <label>Message</label>
                        {errors.message && touched.message && (
                          <p style={{ color: "red" }}>{errors.message}</p>
                        )}
                      </div>
                      <div className="mb-3">
                        <Recaptcha
                          sitekey="6Le_2UMaAAAAAEFwCX7OTpZz6SdZlFx_DET7Pv-V"
                          render="explicit"
                          theme="light"
                          verifyCallback={(response) => {
                            setFieldValue("recaptcha", response);
                          }}
                          onloadCallback={() => {
                            console.log("done loading!");
                          }}
                        />
                        {errors.recaptcha && touched.recaptcha && (
                          <p style={{ color: "red" }}>{errors.recaptcha}</p>
                        )}
                      </div>
                      <div className="submit-btn">
                        <button type="submit">
                          {this.props.loading ? "Sending..." : "Submit"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.loading,
});
const mapDispatchToProps = { sendEmail };
export default connect(mapStateToProps, mapDispatchToProps)(Contact);
