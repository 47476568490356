import React from "react";
import Header from "../Header";
import { Redirect } from "react-router-dom";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { fetchServices, deleteService } from "../../../store/actions";

class ServicesList extends React.Component {
  state = { redirect: false, redirectState: "" };
  componentDidMount() {
    this.props.fetchServices();
  }
  async delete(id) {
    await this.props.deleteService(id, this.props.token);
    this.props.fetchServices();
  }

  edit(id) {
    this.setState({ redirect: "/admin/services/edit" });
    this.setState({ redirectState: { serviceid: id } });
  }
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: this.state.redirectState,
          }}
        />
      );
    }
    return (
      <div>
        <Header />
        <Container className="mt-3">
          <Row className="mb-3">
            <Col sm={6} className="text-left">
              <h4>List of services</h4>
            </Col>
            <Col sm={6} className="text-right">
              <Button
                onClick={() =>
                  this.setState({ redirect: "/admin/services/new" })
                }
              >
                Add Service
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Icon</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.services.map((service) => {
                    return (
                      <tr key={service.id}>
                        <td>{service.id}</td>
                        <td>{service.title}</td>
                        <td>{service.description}</td>
                        <td>
                          <i class={`fa ${service.icon} mr-1`}></i>
                          {service.icon}
                        </td>
                        <td>
                          <Button
                            variant="secondary"
                            onClick={() => this.edit(service.id)}
                          >
                            Edit
                          </Button>
                          &nbsp;&nbsp;
                          <Button
                            variant="danger"
                            onClick={() => this.delete(service.id)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  token: state.access_token,
  services: state.services,
});
const mapDispatchToProps = { fetchServices, deleteService };
export default connect(mapStateToProps, mapDispatchToProps)(ServicesList);
