import React from "react";

const Header = () => {
  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: `<video autoPlay muted loop playsinline id="myVideo">
      <source src="images/bg2.mp4" type="video/mp4" />
    </video>`,
        }}
      />

      <div className="logo">
        <a href="#page-top">
          <img src="../images/logo.png" />
        </a>
      </div>
      <a className="menu-toggle rounded" href="#">
        <i className="fa fa-bars"></i>
      </a>
      <nav id="sidebar-wrapper">
        <ul className="sidebar-nav">
          <li className="sidebar-brand">
            <a className="smooth-scroll" href="#Header"></a>
          </li>
          <li className="sidebar-nav-item">
            <a className="smooth-scroll" href="#page-top">
              Home
            </a>
          </li>
          <li className="sidebar-nav-item">
            <a className="smooth-scroll" href="#Testimonials">
              Artists
            </a>
          </li>
          <li className="sidebar-nav-item">
            <a className="smooth-scroll" href="#Distribution">
              Distribution
            </a>
          </li>
          <li className="sidebar-nav-item">
            <a className="smooth-scroll" href="#Portfolio">
              New Releases
            </a>
          </li>

          <li className="sidebar-nav-item">
            <a className="smooth-scroll" href="#Services">
              Services
            </a>
          </li>
          <li className="sidebar-nav-item">
            <a className="smooth-scroll" href="#Publishing">
              Publishing
            </a>
          </li>
          <li className="sidebar-nav-item">
            <a className="smooth-scroll" href="#News">
              News
            </a>
          </li>
          <li className="sidebar-nav-item">
            <a className="smooth-scroll" href="#About">
              About
            </a>
          </li>
          <li className="sidebar-nav-item">
            <a className="smooth-scroll" href="#Contact">
              Contact
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Header;
