import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import { makeUserLogin } from "../../store/actions";
import "./custom-style.css";
const schema = yup.object({
  email: yup
    .string()
    .email("Email must be a valid email")
    .required("Email is a required field"),
  password: yup.string().required("Password is a required field"),
});
class Login extends React.Component {
  onFormSubmit = (values) => {
    var form_data = new FormData();

    for (var key in values) {
      form_data.append(key, values[key]);
    }
    this.props.makeUserLogin(form_data);
  };

  render() {
    return (
      <div className="login-form">
        <div className="container">
          <Row className="text-center">
            <Col xs={12}>
              <img
                className="img-fluid w-25"
                src="images/logo.png"
                alt="TopDot"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Formik
                validationSchema={schema}
                onSubmit={this.onFormSubmit}
                initialValues={{
                  email: "",
                  password: "",
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                }) => (
                  <Form
                    className="form-signin"
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <Form.Group controlId="formEmail">
                      <Form.Control
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.email && !errors.email}
                        isInvalid={touched.email && errors.email}
                        placeholder="Enter email"
                        style={{ backgroundImage: "none" }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formPassword">
                      <Form.Control
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.password && errors.password}
                        isValid={touched.password && !errors.password}
                        placeholder="Password"
                        style={{ backgroundImage: "none" }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="formSubmit">
                      <Button
                        type="submit"
                        className="btn btn-lg btn-primary btn-block"
                      >
                        Sign in
                      </Button>
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = { makeUserLogin };
export default connect(null, mapDispatchToProps)(Login);
