import React, { useState, useEffect } from "react";
import Gmodel from "../../components/Gmodel";

const Footer = () => {
  const [showmodel, setShowmodel] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [Disclaimer, setDisclaimer] = useState("");
  const [Faq, setFaq] = useState("");
  const [Policy, setPolicy] = useState("");
  useEffect(() => {
    fetch("client/policy.json")
      .then((response) => response.json())
      .then((data) => setPolicy(data));
    fetch("client/disclaimer.json")
      .then((response) => response.json())
      .then((data) => setDisclaimer(data));
    fetch("client/faq.json")
      .then((response) => response.json())
      .then((data) => setFaq(data));
  }, []);
  const handleOnClick = (title, description) => {
    setTitle(title);
    setDescription(description);
    setShowmodel(true);
  };

  const handleOnhide = () => {
    setTitle("");
    setDescription("");
    setShowmodel(false);
  };
  return (
    <>
      <footer className="footer text-center">
        <div className="container">
          <ul className="list-inline justify-content-center">
            <li className="list-inline-item p-0 pl-1">
              <a
                className="social-link rounded-circle text-white mr-3"
                href="https://www.facebook.com/Top-Dot-Music-103050581714938/"
                target="_blank"
              >
                <i className="fa fa-facebook" aria-hidden="true"></i>
              </a>
            </li>
            <li className="list-inline-item p-0">
              <a
                className="social-link rounded-circle text-white mr-3"
                href="https://www.instagram.com/topdotmusic/"
                target="_blank"
              >
                <i className="fa fa-instagram" aria-hidden="true"></i>
              </a>
            </li>
            <li className="list-inline-item p-0">
              <a
                className="social-link rounded-circle text-white mr-3"
                href="https://twitter.com/topdotmusic"
                target="_blank"
              >
                <i className="fa fa-twitter" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
          <p className="mb-0">
            <a
              href="#"
              className="text-muted small mb-0"
              onClick={() => handleOnClick("Disclaimer", Disclaimer.text)}
            >
              Disclaimer
            </a>
            &nbsp;&nbsp;
            <a
              href="#"
              className="text-muted small mb-0"
              onClick={() => handleOnClick("Privacy Policy", Policy.text)}
            >
              Privacy Policy
            </a>
            &nbsp;&nbsp;
            <a
              href="#"
              className="text-muted small mb-0"
              onClick={() => handleOnClick("FAQ", Faq.text)}
            >
              FAQ
            </a>
          </p>
          <p className="text-muted small mb-0">Copyright © TopDot</p>
          {/* <p className="text-muted small mb-0">
          Designed by{" "}
          <a href="#" target="_balnk">
            GUJCARE
          </a>
        </p> */}
        </div>
      </footer>
      <Gmodel
        show={showmodel}
        handleOnhide={handleOnhide}
        title={title}
        description={description}
      />
    </>
  );
};

export default Footer;
