import React from "react";
import Header from "../Header";
import { Redirect } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import { insertRelease, fetchReleases } from "../../../store/actions";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

const schema = yup.object({
  albumtitle: yup.string().required(),
  albumdesc: yup.string().required(),
  image: yup.string().required(),
});

class ReleasesNew extends React.Component {
  state = { redirect: false };
  onFormSubmit = (values) => {
    this.insertRelease(values);
    this.setState({ redirect: "/admin/releases" });
  };

  async insertRelease(values) {
    const formdata = new FormData();
    for (var key in values) {
      formdata.append(key, values[key]);
    }
    await this.props.insertRelease(formdata, this.props.token);
    this.props.fetchReleases();
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <div>
        <Header />
        <Container className="mt-3">
          <Row className="mb-3">
            <Col sm={6} className="text-left">
              <h4>Add new release</h4>
            </Col>
            <Col sm={6} className="text-right">
              <Button
                variant="light"
                onClick={() => this.setState({ redirect: "/admin/releases" })}
              >
                Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Formik
                validationSchema={schema}
                onSubmit={this.onFormSubmit}
                initialValues={{
                  albumtitle: "",
                  albumdesc: "",
                  image: "",
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  setFieldValue,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik101"
                      >
                        <Form.Label>Album title</Form.Label>
                        <Form.Control
                          type="text"
                          name="albumtitle"
                          value={values.albumtitle}
                          onChange={handleChange}
                          isInvalid={!!errors.albumtitle}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.albumtitle}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik102"
                      >
                        <Form.Label>Album desc</Form.Label>
                        <Form.Control
                          type="text"
                          name="albumdesc"
                          value={values.albumdesc}
                          onChange={handleChange}
                          isInvalid={!!errors.albumdesc}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.albumdesc}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Group>
                      <Form.File
                        className="position-relative"
                        name="image"
                        onChange={(event) => {
                          setFieldValue("image", event.currentTarget.files[0]);
                        }}
                        isInvalid={!!errors.image}
                        feedback={errors.image}
                        id="validationFormik107"
                      />
                      {errors.image ? (
                        <div
                          class="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.image}
                        </div>
                      ) : null}
                    </Form.Group>

                    <Button type="submit">Submit form</Button>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  token: state.access_token,
});
const mapDispatchToProps = { insertRelease, fetchReleases };
export default connect(mapStateToProps, mapDispatchToProps)(ReleasesNew);
