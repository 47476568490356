import {
  USER_AUTH,
  API_REQUEST,
  RESPONSE_ARTISTS,
  API_ERROR,
  RESPONSE_RELEASES,
  RESPONSE_STATUS,
  RESPONSE_MESSAGE,
  RESPONSE_DISTRIBUTIONS,
  RESPONSE_SERVICES,
  RESET_TOASTER,
} from "../constants";

const defaultState = {
  releases: [],
  artists: [],
  distributions: [],
  services: [],
  loggedIn: false,
  loading: false,
  error: "",
  access_token: "",
  status: "",
  message: "",
};
const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case USER_AUTH:
      return {
        ...state,
        access_token: action.payload,
        loggedIn: true,
      };
    case API_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        status: "",
        message: "",
      };
    case RESPONSE_ARTISTS:
      return {
        ...state,
        artists: action.payload,
        loading: false,
        error: "",
      };
    case RESPONSE_RELEASES:
      return {
        ...state,
        releases: action.payload,
        loading: false,
        error: "",
      };
    case RESPONSE_DISTRIBUTIONS:
      return {
        ...state,
        distributions: action.payload,
        loading: false,
        error: "",
      };
    case RESPONSE_SERVICES:
      return {
        ...state,
        services: action.payload,
        loading: false,
        error: "",
      };
    case RESPONSE_STATUS:
      return {
        ...state,
        status: action.payload,
        loading: false,
        error: "",
      };
    case RESPONSE_MESSAGE:
      return {
        ...state,
        message: action.payload,
        loading: false,
        error: "",
      };
    case API_ERROR: {
      return {
        ...state,
        loading: false,
        status: "fail",
        message: action.payload,
        error: action.payload,
      };
    }
    case RESET_TOASTER: {
      return {
        ...state,
        loading: false,
        status: "",
        message: "",
      };
    }

    default:
      return state;
  }
};

export default reducer;
