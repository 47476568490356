import React from "react";
import { Modal, Row, Col, ListGroup } from "react-bootstrap";
import { ARTIST_IMAGE_URL } from "../../store/constants";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
const imgUrl = ARTIST_IMAGE_URL;
const Model = (props) => {
  const { profileimage, artist } = props;
  return (
    <Modal className="artist-modal" {...props} size="lg" centered>
      <Modal.Header>
        <Modal.Title style={{ display: "block", width: "100%" }}>
          <Row>
            <div
              className="close-btn col-md-12"
              style={{
                position: "absolute",
                zIndex: "1111",
                top: "0",
                left: "0",
                width: "100%",
                textAlign: "right",
                height: "3%",
              }}
            >
              <img
                src="images/close.png"
                style={{ height: "16px", width: "16px", position: "absolute" }}
                onClick={props.onHide}
                alt=""
              />
            </div>
          </Row>
          <Row>
            {artist && artist.music ? (
              <Col md={12}>
                <AudioPlayer src={imgUrl + artist.music} />
              </Col>
            ) : null}
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {artist ? (
          <>
            <Row>
              <Col sm={4} className="left-model">
                <img width="200" height="200" src={profileimage} />
                <Col sm={12} className="modal-footer mt-3">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <a
                        className="social-link rounded-circle text-white"
                        href={artist.insta}
                        target="_blank"
                      >
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="social-link rounded-circle text-white"
                        href={artist.fb}
                        target="_blank"
                      >
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="social-link rounded-circle text-white"
                        href={artist.twitter}
                        target="_blank"
                      >
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="social-link rounded-circle text-white"
                        href={artist.linkedin}
                        target="_blank"
                      >
                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col sm={12} className="mt-2 pl-0">
                  <ListGroup className="d-none" variant="flush">
                    {/* <ListGroup.Item className="p-1">
                      DOB:&nbsp;&nbsp;{artist.dob}
                    </ListGroup.Item> */}
                    <ListGroup.Item className="p-1">
                      Latest Album:&nbsp;&nbsp;{artist.latestalbum}
                    </ListGroup.Item>
                    <ListGroup.Item className="p-1">
                      Albums:&nbsp;&nbsp;{artist.albums}{" "}
                    </ListGroup.Item>
                    <ListGroup.Item className="p-1">
                      Recordings#:&nbsp;&nbsp;{artist.recordings}
                    </ListGroup.Item>
                    <ListGroup.Item className="p-1">
                      Role:&nbsp;&nbsp;{artist.role}{" "}
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Col>
              <Col sm={8}>
                <h3>{artist.name}</h3>
                <p>{artist.about}</p>
                <Row
                  style={{ position: "absolute", bottom: "0" }}
                  className="musiclinks my-3 text-center"
                >
                  <table className="border-0">
                    <tr>
                      <th>
                        <img src="images/amazon-music.svg" />
                      </th>
                      <th>
                        <img src="images/apple-music-sg.svg" />
                      </th>
                      <th>
                        <img src="images/spotify-sg.svg" />
                      </th>
                      <th>
                        <img src="images/youtubemusic.svg" />
                      </th>
                    </tr>
                    <tr>
                      <td>
                        <a href={artist.amazonmusic} target="_blank">
                          Listen
                        </a>
                      </td>
                      <td>
                        <a href={artist.applemusic} target="_blank">
                          Listen
                        </a>
                      </td>
                      <td>
                        <a href={artist.spotify} target="_blank">
                          Listen
                        </a>
                      </td>
                      <td>
                        <a href={artist.youtubemusic} target="_blank">
                          Listen
                        </a>
                      </td>
                    </tr>
                  </table>
                </Row>
              </Col>
            </Row>
          </>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default Model;
