import React from "react";
import Header from "../Header";
import { Redirect } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import { insertDistribution, fetchDistributions } from "../../../store/actions";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

const schema = yup.object({
  title: yup.string().required(),
  icon: yup.string().required(),
});

class DistributionsNew extends React.Component {
  state = { redirect: false };
  onFormSubmit = (values) => {
    values.dob = values.dateofbirth;
    values.image = values.profileimage;
    values.profileimage = "";
    this.insertArtist(values);
    this.setState({ redirect: "/admin/distributions" });
  };

  async insertArtist(values) {
    const formdata = new FormData();
    for (var key in values) {
      formdata.append(key, values[key]);
    }
    await this.props.insertDistribution(formdata, this.props.token);
    this.props.fetchDistributions();
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <div>
        <Header />
        <Container className="mt-3">
          <Row className="mb-3">
            <Col sm={6} className="text-left">
              <h4>Add new distribution</h4>
            </Col>
            <Col sm={6} className="text-right">
              <Button
                variant="light"
                onClick={() =>
                  this.setState({ redirect: "/admin/distributions" })
                }
              >
                Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Formik
                validateOnChange={false}
                validationSchema={schema}
                onSubmit={this.onFormSubmit}
                initialValues={{
                  title: "",
                  icon: "",
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  setFieldValue,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik101"
                      >
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          type="text"
                          name="title"
                          value={values.title}
                          onChange={handleChange}
                          isInvalid={!!errors.title}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.title}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>

                    <Form.Group>
                      <Form.File
                        className="position-relative"
                        name="icon"
                        onChange={(event) => {
                          setFieldValue("icon", event.currentTarget.files[0]);
                        }}
                        isInvalid={!!errors.icon}
                        feedback={errors.icon}
                        id="validationFormik107"
                        feedbackTooltip
                      />
                      {errors.icon ? (
                        <div
                          class="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {errors.icon}
                        </div>
                      ) : null}
                    </Form.Group>

                    <Button type="submit">Submit form</Button>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  token: state.access_token,
});
const mapDispatchToProps = { insertDistribution, fetchDistributions };
export default connect(mapStateToProps, mapDispatchToProps)(DistributionsNew);
