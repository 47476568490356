import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper";
import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";
import connectedAuthWrapper from "redux-auth-wrapper/connectedAuthWrapper";

//import Loading from '../components/Layout/Loading';

const locationHelper = locationHelperBuilder({});

const userIsAuthenticatedDefaults = {
  authenticatingSelector: (state) => state.loading,
  authenticatedSelector: (state) => state.loggedIn,
  wrapperDisplayName: "UserIsAuthenticated",
};

export const userIsAuthenticated = connectedAuthWrapper(
  userIsAuthenticatedDefaults
);

export const userIsAuthenticatedRedir = connectedRouterRedirect({
  ...userIsAuthenticatedDefaults,
  //AuthenticatingComponent: Loading,
  redirectPath: "/login",
  allowRedirectBack: false,
});

const userIsNotAuthenticatedDefaults = {
  // Want to redirect the user when they are done loading and authenticated
  authenticatingSelector: (state) => state.loading,
  authenticatedSelector: (state) => !state.loggedIn,
  wrapperDisplayName: "UserIsNotAuthenticated",
};

export const userIsNotAuthenticated = connectedAuthWrapper(
  userIsNotAuthenticatedDefaults
);

export const userIsNotAuthenticatedRedir = connectedRouterRedirect({
  ...userIsNotAuthenticatedDefaults,
  redirectPath: "/admin/artists",
  //AuthenticatingComponent: Loading,
  allowRedirectBack: false,
});
