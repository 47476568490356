import React from "react";
import Header from "../Header";
import { Redirect } from "react-router-dom";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { fetchArtists, deleteArtist } from "../../../store/actions";
import { ARTIST_IMAGE_URL } from "../../../store/constants";

const imgUrl = ARTIST_IMAGE_URL;
class ArtistsList extends React.Component {
  state = { redirect: false, redirectState: "" };
  componentDidMount() {
    this.props.fetchArtists();
  }
  async delete(id) {
    await this.props.deleteArtist(id, this.props.token);
    this.props.fetchArtists();
  }

  edit(id) {
    this.setState({ redirect: "/admin/artists/edit" });
    this.setState({ redirectState: { artistid: id } });
  }
  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            state: this.state.redirectState,
          }}
        />
      );
    }
    return (
      <div>
        <Header />
        <Container className="mt-3">
          <Row className="mb-3">
            <Col sm={6} className="text-left">
              <h4>List of artists</h4>
            </Col>
            <Col sm={6} className="text-right">
              <Button
                onClick={() =>
                  this.setState({ redirect: "/admin/artists/new" })
                }
              >
                Add Artist
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>DateOfBirth</th>
                    <th>Latest Album</th>
                    <th>Albums</th>
                    <th>Recordings</th>
                    <th>Role</th>
                    <th>Profile image</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.artists.map((artist) => {
                    return (
                      <tr key={artist.id}>
                        <td>{artist.id}</td>
                        <td>{artist.name}</td>
                        <td>{artist.dob}</td>
                        <td>{artist.latestalbum}</td>
                        <td>{artist.albums}</td>
                        <td>{artist.recordings}</td>
                        <td>{artist.role}</td>
                        <td>
                          <img
                            src={imgUrl + artist.image}
                            width="40"
                            height="40"
                            alt={artist.name}
                          />
                        </td>
                        <td>
                          <Button
                            variant="secondary"
                            onClick={() => this.edit(artist.id)}
                          >
                            Edit
                          </Button>
                          &nbsp;&nbsp;
                          <Button
                            variant="danger"
                            onClick={() => this.delete(artist.id)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  token: state.access_token,
  artists: state.artists,
});
const mapDispatchToProps = { fetchArtists, deleteArtist };
export default connect(mapStateToProps, mapDispatchToProps)(ArtistsList);
