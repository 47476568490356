import React from "react";
import Header from "../Header";
import { Redirect } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import { updateService, fetchServices } from "../../../store/actions";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Select from "react-select";
import faList from "../../../utils/font-awesome.json";
import _ from "lodash";
import { forEach } from "lodash";

const schema = yup.object({
  title: yup.string().required(),
  description: yup.string().required(),
  icon: yup.string().required(),
});

class ServicesEdit extends React.Component {
  state = { redirect: false };
  onFormSubmit = (values) => {
    this.updateService(values);
    this.setState({ redirect: "/admin/services" });
  };

  async updateService(values) {
    const formdata = new FormData();
    for (var key in values) {
      formdata.append(key, values[key]);
    }
    formdata.append("_method", "PUT");
    await this.props.updateService(values.id, formdata, this.props.token);
    this.props.fetchServices();
  }

  formatOptionLabel = ({ value, label }) => (
    <div style={{ display: "flex" }}>
      <div style={{ marginRight: "10px" }}>
        <i class={`fa ${value}`} />
      </div>
      <div>{label}</div>
    </div>
  );

  render() {
    const optionsRaw = faList["4.7.0"];
    let options = [];
    forEach(optionsRaw, (item) => {
      options.push({ value: item, label: item });
    });
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    const { id, title, description, icon } = this.props.service;
    return (
      <div>
        <Header />
        <Container className="mt-3">
          <Row className="mb-3">
            <Col sm={6} className="text-left">
              <h4>Edit service</h4>
            </Col>
            <Col sm={6} className="text-right">
              <Button
                variant="light"
                onClick={() => this.setState({ redirect: "/admin/services" })}
              >
                Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Formik
                validateOnChange={false}
                validationSchema={schema}
                onSubmit={this.onFormSubmit}
                initialValues={{
                  id,
                  title,
                  description,
                  icon,
                }}
              >
                {({ handleSubmit, handleChange, values, errors }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik101"
                      >
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          type="text"
                          name="title"
                          value={values.title}
                          onChange={handleChange}
                          isInvalid={!!errors.title}
                        />
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors.title}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik106"
                      >
                        <Form.Label>Describe service</Form.Label>
                        <textarea
                          className="form-control"
                          type="text"
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                          isInvalid={!!errors.description}
                        >
                          {values.description}
                        </textarea>
                        {errors.description ? (
                          <div
                            class="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors.description}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik101"
                      >
                        <Form.Label>Icon</Form.Label>
                        <Select
                          isSearchable
                          name="icon"
                          options={options}
                          defaultValue={{
                            label: values.icon,
                            value: values.icon,
                          }}
                          onChange={(selectedOption) => {
                            values.icon = selectedOption.value;
                            handleChange("icon");
                          }}
                          formatOptionLabel={this.formatOptionLabel}
                        />
                        {errors.icon ? (
                          <div
                            class="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors.icon}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Form.Row>
                    <Button type="submit">Submit form</Button>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const service = _.find(state.services, {
    id: ownProps.location.state.serviceid,
  });
  return {
    token: state.access_token,
    service,
  };
};
const mapDispatchToProps = { updateService, fetchServices };
export default connect(mapStateToProps, mapDispatchToProps)(ServicesEdit);
