export const USER_AUTH = "USER_AUTH";
export const API_REQUEST = "API_REQUEST";
export const RESPONSE_ARTISTS = "RESPONSE_ARTISTS";
export const RESPONSE_RELEASES = "RESPONSE_RELEASES";
export const RESPONSE_DISTRIBUTIONS = "RESPONSE_DISTRIBUTIONS";
export const RESPONSE_SERVICES = "RESPONSE_SERVICES";
export const API_ERROR = "API_ERROR";
export const RESPONSE_STATUS = "RESPONSE_STATUS";
export const RESPONSE_MESSAGE = "RESPONSE_MESSAGE";
export const RESET_TOASTER = "RESET_TOASTER";

const BASE_URL = "https://topdotmusic.com/topdotapi/api/";
const BASE_URL_IMAGE = "https://topdotmusic.com/topdotapi/img/";
//const BASE_URL = "http://localhost/topdot/api/api/";
//const BASE_URL_IMAGE = "http://localhost/topdot/api/img/";

export const LOGIN_URL = BASE_URL + "login";
export const SENDEMAIL_URL = BASE_URL + "sendemail";
export const ARTISTS_URL = BASE_URL + "artists/";
export const RELEASES_URL = BASE_URL + "releases/";
export const SERVICES_URL = BASE_URL + "services/";
export const DISTRIBUTIONS_URL = BASE_URL + "distributions/";
export const ARTIST_IMAGE_URL = BASE_URL_IMAGE + "artists/";
export const RELEASE_IMAGE_URL = BASE_URL_IMAGE + "releases/";
export const DISTRIBUTION_IMAGE_URL = BASE_URL_IMAGE + "distributions/";
