import React from "react";
import Login from "../Admin/Login";
import Public from "../Public";
import ArtistsList from "../Admin/Artists";
import ArtistsNew from "../Admin/Artists/new";
import ArtistsEdit from "../Admin/Artists/edit";
import ReleasesList from "../Admin/Releases";
import ReleaseNew from "../Admin/Releases/new";
import DistributionsList from "../Admin/Distributions";
import DistributionsNew from "../Admin/Distributions/new";
import ServicesList from "../Admin/Services";
import ServicesNew from "../Admin/Services/new";
import ServicesEdit from "../Admin/Services/edit";
import NotFound from "../NotFound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  userIsAuthenticatedRedir,
  userIsNotAuthenticatedRedir,
} from "../../utils/auth";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
class App extends React.Component {
  render() {
    return (
      <>
        <ToastContainer />
        <Router basename="/">
          <Switch>
            <Route
              exact
              path="/admin/services/edit"
              component={userIsAuthenticatedRedir(ServicesEdit)}
            />
            <Route
              exact
              path="/admin/services/new"
              component={userIsAuthenticatedRedir(ServicesNew)}
            />
            <Route
              exact
              path="/admin/services"
              component={userIsAuthenticatedRedir(ServicesList)}
            />
            <Route
              exact
              path="/admin/distributions/new"
              component={userIsAuthenticatedRedir(DistributionsNew)}
            />
            <Route
              exact
              path="/admin/distributions"
              component={userIsAuthenticatedRedir(DistributionsList)}
            />
            <Route
              exact
              path="/admin/releases/new"
              component={userIsAuthenticatedRedir(ReleaseNew)}
            />
            <Route
              exact
              path="/admin/releases"
              component={userIsAuthenticatedRedir(ReleasesList)}
            />
            <Route
              exact
              path="/admin/artists/edit"
              component={userIsAuthenticatedRedir(ArtistsEdit)}
            />
            <Route
              exact
              path="/admin/artists/new"
              component={userIsAuthenticatedRedir(ArtistsNew)}
            />
            <Route
              exact
              path="/admin/artists"
              component={userIsAuthenticatedRedir(ArtistsList)}
            />
            <Route
              exact
              path="/login"
              component={userIsNotAuthenticatedRedir(Login)}
            />
            <Route exact path="/" component={Public} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </>
    );
  }
}

export default App;
