import React from "react";
import { connect } from "react-redux";
import Modal from "../Modal";
import { fetchArtists } from "../../store/actions";
import { ARTIST_IMAGE_URL } from "../../store/constants";
import $ from "jquery";
import slick from "slick-carousel";
const imgUrl = ARTIST_IMAGE_URL;

class Artists extends React.Component {
  state = {
    modelShow: false,
    name: "",
    description: "",
    profileimage: "",
    instalink: "",
    fblink: "",
    twitterlink: "",
    linkedinlink: "",
  };
  componentDidMount() {
    let self = this;
    //this.slider.slickGoTo(this.slider.slickCurrentSlide + 1);
    this.initialArtistSlider(self);
  }
  async initialArtistSlider(self) {
    await this.props.fetchArtists();
    $(".artist").slick({
      alwaysSlide:
        this.props.artists && this.props.artists.length < 4 ? true : false,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      // prevArrow: <span className="owl-prev"></span>,
      // nextArrow: <span className="owl-next"></span>,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    $(".artistmodel").on("click", function (event) {
      event.preventDefault();
      let artist = $(this).data("artist");
      let image = $(this).data("image");
      self.openModel(artist, image);
    });
  }

  openModel(artist, profileimage) {
    this.setState({
      modelShow: true,
      artist,
      profileimage,
    });
  }
  render() {
    return (
      <>
        <section id="Testimonials" className="content-section">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="block-heading">
                  <h1>Artists</h1>
                </div>
                <div className="artist">
                  {this.props.artists
                    ? this.props.artists.map((artist) => {
                        return (
                          <div key={artist.id} className="service-box item">
                            <div className="service-icon yellow">
                              <div className="front-content">
                                <img
                                  className="img-fluid"
                                  src={imgUrl + artist.image}
                                />
                              </div>
                            </div>
                            <div
                              className="service-content"
                              style={{
                                backgroundImage: `url('${imgUrl}${artist.image}')`,
                              }}
                            >
                              <p className="mt-1 d-none">
                                {/* DOB: {artist.dob}
                                <br /> */}
                                Latest Album: {artist.latestalbum}
                                <br />
                                Albums: {artist.albums}
                                <br />
                                Recordings: {artist.recordings}
                                <br />
                                Role : {artist.role}
                                <br />
                              </p>
                              <div
                                className="testimonial-name"
                                style={{ marginTop: "4.2em" }}
                              >
                                <a
                                  className="modal-btn artistmodel"
                                  data-image={imgUrl + artist.image}
                                  data-artist={JSON.stringify(artist)}
                                >
                                  {artist.name}
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Modal
          show={this.state.modelShow}
          onHide={() => this.setState({ modelShow: false })}
          profileimage={this.state.profileimage}
          artist={this.state.artist}
        ></Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  artists: state.artists,
});
const mapDispatchToProps = { fetchArtists };
export default connect(mapStateToProps, mapDispatchToProps)(Artists);
