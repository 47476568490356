import React from "react";
import Feed from "../Feed";

const News = () => {
  return (
    <section id="News" className="content-section">
      <div className="container text-center">
        <div className="row">
          <div className="col-lg-12">
            <div className="block-heading">
              <h1>News</h1>
            </div>

            <Feed />
          </div>
        </div>
      </div>
    </section>
  );
};

export default News;
