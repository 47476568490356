import React, { useState, useEffect } from "react";

const Publishing = () => {
  const [publishingText, setpublishingText] = useState("");
  useEffect(() => {
    fetch("client/publishing.json")
      .then((response) => response.json())
      .then((data) => setpublishingText(data));
  }, []);
  return (
    <section id="Publishing" className="content-section">
      <div className="container text-center">
        <div className="row">
          <div className="col-lg-12">
            <div className="block-heading">
              <h1>Publishing</h1>
            </div>
            <div className="row">
              <div className="lead text-md-left">
                {publishingText.text}
                <br />
                <br />
                <h3>Label Services</h3>
                {publishingText["label-services-1"]}
                <br />
                <div className="text-center pl-2">
                  <p className="myquote">{publishingText["banner-1"]}</p>
                </div>
                {publishingText["label-services-2"]}
                <div className="text-center pl-2">
                  <p className="myquote">{publishingText["banner-2"]}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Publishing;
