import React from "react";
import Header from "../Header";
import { Redirect } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import { updateArtist, fetchArtists } from "../../../store/actions";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import _ from "lodash";

const schema = yup.object({
  name: yup.string().required(),
  dateofbirth: yup.string().required(),
  latestalbum: yup.string().required(),
  albums: yup.string().required(),
  recordings: yup.string().required(),
  role: yup.string().required(),
  about: yup.string().required(),
  insta: yup.string().url().required(),
  fb: yup.string().url().required(),
  twitter: yup.string().url().required(),
  linkedin: yup.string().url().required(),
  applemusic: yup.string().url().required(),
  spotify: yup.string().url().required(),
  youtubemusic: yup.string().url().required(),
  amazonmusic: yup.string().url().required(),
});

class ArtistsEdit extends React.Component {
  state = { redirect: false };
  onFormSubmit = (values) => {
    values.dob = values.dateofbirth;
    values.image = values.profileimage;
    values.profileimage = "";
    this.updateArtist(values);
    this.setState({ redirect: "/admin/artists" });
  };

  async updateArtist(values) {
    const formdata = new FormData();
    for (var key in values) {
      formdata.append(key, values[key]);
    }
    formdata.append("_method", "PUT");
    await this.props.updateArtist(values.id, formdata, this.props.token);
    this.props.fetchArtists();
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    const {
      id,
      name,
      about,
      dob,
      latestalbum,
      albums,
      recordings,
      role,
      image,
      insta,
      fb,
      twitter,
      linkedin,
      music,
      applemusic,
      amazonmusic,
      youtubemusic,
      spotify,
    } = this.props.artist;
    return (
      <div>
        <Header />
        <Container className="mt-3">
          <Row className="mb-3">
            <Col sm={6} className="text-left">
              <h4>Edit artist</h4>
            </Col>
            <Col sm={6} className="text-right">
              <Button
                variant="light"
                onClick={() => this.setState({ redirect: "/admin/artists" })}
              >
                Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Formik
                validateOnChange={false}
                validationSchema={schema}
                onSubmit={this.onFormSubmit}
                initialValues={{
                  id,
                  name,
                  about,
                  dateofbirth: dob,
                  latestalbum,
                  albums,
                  recordings,
                  role,
                  profileimage: image,
                  insta,
                  fb,
                  twitter,
                  linkedin,
                  music,
                  applemusic,
                  amazonmusic,
                  youtubemusic,
                  spotify,
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  setFieldValue,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik101"
                      >
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={!!errors.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik102"
                      >
                        <Form.Label>Date of Birth</Form.Label>
                        <Form.Control
                          type="date"
                          name="dateofbirth"
                          value={values.dateofbirth}
                          onChange={handleChange}
                          isInvalid={!!errors.dateofbirth}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.dateofbirth}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormikUsername2"
                      >
                        <Form.Label>Latest album</Form.Label>
                        <Form.Control
                          type="text"
                          name="latestalbum"
                          value={values.latestalbum}
                          onChange={handleChange}
                          isInvalid={!!errors.latestalbum}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.latestalbum}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik103"
                      >
                        <Form.Label>Albums</Form.Label>
                        <Form.Control
                          type="text"
                          name="albums"
                          value={values.albums}
                          onChange={handleChange}
                          isInvalid={!!errors.albums}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.albums}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik104"
                      >
                        <Form.Label>Recordings</Form.Label>
                        <Form.Control
                          type="text"
                          name="recordings"
                          value={values.recordings}
                          onChange={handleChange}
                          isInvalid={!!errors.recordings}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.recordings}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik105"
                      >
                        <Form.Label>Role</Form.Label>
                        <Form.Control
                          type="text"
                          name="role"
                          value={values.role}
                          onChange={handleChange}
                          isInvalid={!!errors.role}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.role}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik106"
                      >
                        <Form.Label>Describe Artist</Form.Label>
                        <textarea
                          className="form-control"
                          type="text"
                          name="about"
                          value={values.about}
                          onChange={handleChange}
                          isInvalid={!!errors.about}
                        >
                          {values.about}
                        </textarea>
                        {errors.about ? (
                          <div
                            class="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors.about}
                          </div>
                        ) : null}
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik102"
                      >
                        <Form.Label>Insta Link</Form.Label>
                        <Form.Control
                          type="text"
                          name="insta"
                          value={values.insta}
                          onChange={handleChange}
                          isInvalid={!!errors.insta}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.insta}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormikUsername2"
                      >
                        <Form.Label>Facebook Link</Form.Label>
                        <Form.Control
                          type="text"
                          name="fb"
                          value={values.fb}
                          onChange={handleChange}
                          isInvalid={!!errors.fb}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.fb}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik106"
                      >
                        <Form.Label>Twitter Link</Form.Label>
                        <Form.Control
                          type="text"
                          name="twitter"
                          value={values.twitter}
                          onChange={handleChange}
                          isInvalid={!!errors.twitter}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.twitter}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik102"
                      >
                        <Form.Label>LinkedIn Link</Form.Label>
                        <Form.Control
                          type="text"
                          name="linkedin"
                          value={values.linkedin}
                          onChange={handleChange}
                          isInvalid={!!errors.linkedin}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.linkedin}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik102"
                      >
                        <Form.Label>Applemusic link</Form.Label>
                        <Form.Control
                          type="text"
                          name="applemusic"
                          value={values.applemusic}
                          onChange={handleChange}
                          isInvalid={!!errors.applemusic}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.applemusic}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik106"
                      >
                        <Form.Label>Spotify Link</Form.Label>
                        <Form.Control
                          type="text"
                          name="spotify"
                          value={values.spotify}
                          onChange={handleChange}
                          isInvalid={!!errors.spotify}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.spotify}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik102"
                      >
                        <Form.Label>Youtubemusic Link</Form.Label>
                        <Form.Control
                          type="text"
                          name="youtubemusic"
                          value={values.youtubemusic}
                          onChange={handleChange}
                          isInvalid={!!errors.youtubemusic}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.youtubemusic}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik102"
                      >
                        <Form.Label>Amazonmusic link</Form.Label>
                        <Form.Control
                          type="text"
                          name="amazonmusic"
                          value={values.amazonmusic}
                          onChange={handleChange}
                          isInvalid={!!errors.amazonmusic}
                        />

                        <Form.Control.Feedback type="invalid">
                          {errors.amazonmusic}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} md="4">
                        <Form.Label>Artist image</Form.Label>
                        <Form.File
                          className="position-relative"
                          name="profileimage"
                          onChange={(event) => {
                            setFieldValue(
                              "profileimage",
                              event.currentTarget.files[0]
                            );
                          }}
                          id="validationFormik107"
                        />
                        {errors.profileimage ? (
                          <div
                            class="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors.profileimage}
                          </div>
                        ) : null}
                      </Form.Group>
                      <Form.Group as={Col} md="4">
                        <Form.Label>Artist song</Form.Label>
                        <Form.File
                          className="position-relative"
                          name="music"
                          onChange={(event) => {
                            setFieldValue(
                              "music",
                              event.currentTarget.files[0]
                            );
                          }}
                          id="validationFormik107"
                        />
                        {errors.music ? (
                          <div
                            class="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors.music}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Form.Row>

                    <Button type="submit">Submit form</Button>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const artist = _.find(state.artists, {
    id: ownProps.location.state.artistid,
  });
  return {
    token: state.access_token,
    artist,
  };
};
const mapDispatchToProps = { updateArtist, fetchArtists };
export default connect(mapStateToProps, mapDispatchToProps)(ArtistsEdit);
