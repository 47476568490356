import React from "react";

const Banner = () => {
  return (
    <section id="Banner" className="content-section">
      <div className="container content-wrap text-center">
        <h1>BE A TOP DOT</h1>
        <h3>
          <p>
            <a href="http://beamwav.topdotmusic.com">
              <img
                src="images/beamware.png"
                alt="Beamwar"
                className="beamwar_logo"
              />
            </a>
          </p>
          <em>The Portal of all Portals</em>
        </h3>
        <a className="btn btn-group-sm btn-xl smooth-scroll" href="#About">
          Enter Portal
        </a>
      </div>
      <div className="overlay"></div>
    </section>
  );
};

export default Banner;
