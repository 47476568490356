import React from "react";
import { connect } from "react-redux";
import { fetchDistributions } from "../../store/actions";
import { slice, concat } from "lodash";
//import Slider from "react-slick";
import { DISTRIBUTION_IMAGE_URL } from "../../store/constants";
const imgUrl = DISTRIBUTION_IMAGE_URL;
// const options = {
//   dots: false,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 5,
//   slidesToScroll: 1,
//   autoplay: true,
//   arrows: false,
//   responsive: [
//     {
//       breakpoint: 1024,
//       settings: {
//         slidesToShow: 3,
//         slidesToScroll: 3,
//         infinite: true,
//         dots: true,
//       },
//     },
//     {
//       breakpoint: 600,
//       settings: {
//         slidesToShow: 2,
//         slidesToScroll: 2,
//       },
//     },
//     {
//       breakpoint: 480,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//       },
//     },
//   ],
// };
class Distribution extends React.Component {
  constructor(props) {
    super(props);
    this.limit = 10;
    this.data = [];
    this.state = {
      showMore: true,
      list: [],
      index: this.limit,
    };
  }
  loadMoresetup = () => {
    this.data = this.props.distributions ? this.props.distributions : null;
    this.setState({
      list: slice(this.data, 0, this.state.index),
      showMore: this.limit < this.data.length,
    });
  };
  componentDidMount() {
    this.fetchDistributions();
  }
  async fetchDistributions() {
    await this.props.fetchDistributions();
    this.loadMoresetup();
  }
  loadMore = () => {
    const newIndex = this.state.index + this.limit;
    const newShowMore = newIndex < this.data.length;
    const newList = concat(
      this.state.list,
      slice(this.data, this.state.index, newIndex)
    );
    this.setState({
      index: newIndex,
      list: newList,
      showMore: newShowMore,
    });
  };
  render() {
    return (
      <section id="Distribution" className="content-section text-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="block-heading">
                <h1>Distribution</h1>
                <div className="mapData"></div>
                <h1>See your streaming data</h1>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="store-guide-list">
                {this.state.list
                  ? this.state.list.map((distribution) => {
                      return (
                        <div
                          key={distribution.title}
                          className="store-guide-list__item"
                        >
                          <a
                            href={distribution.title}
                            className="store-guide-list__link"
                            target="_blank"
                          >
                            <img
                              src={imgUrl + distribution.icon}
                              className="store-guide-list__img g-image"
                            />
                          </a>
                        </div>
                      );
                    })
                  : null}
              </div>
              {/* <Slider {...options}>
                {this.props.distributions
                  ? this.props.distributions.map((distribution) => {
                      return (
                        <div
                          key={distribution.id}
                          className="text-center  brand-slider"
                        >
                          <img
                            className="img-responsive mx-auto"
                            src={imgUrl + distribution.icon}
                            alt={distribution.title}
                          />
                        </div>
                      );
                    })
                  : null}
              </Slider> */}
              {this.state.showMore && (
                <button
                  className="btn btn-light btn-lg"
                  onClick={this.loadMore}
                >
                  {" "}
                  Load More{" "}
                </button>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  distributions: state.distributions,
});
const mapDispatchToProps = { fetchDistributions };

export default connect(mapStateToProps, mapDispatchToProps)(Distribution);
