import React from "react";
import { connect } from "react-redux";
import { fetchServices } from "../../store/actions";
import Gmodel from "../../components/Gmodel";

class Services extends React.Component {
  state = { servicesText: "", showmodel: false, title: "", description: "" };
  componentDidMount() {
    this.props.fetchServices();
    fetch("client/services.json")
      .then((response) => response.json())
      .then((data) => this.setState({ servicesText: data }));
  }
  handleOnClick = (title, description) => {
    this.setState({ showmodel: true, title, description });
  };
  handleOnhide = () => {
    this.setState({ showmodel: false, title: "", description: "" });
  };
  render() {
    return (
      <>
        <section id="Services" className="content-section text-center">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="block-heading">
                  <h1>Services</h1>
                </div>
                <div className="lead text-md-left">
                  <p>{this.state.servicesText["service-top"]}</p>
                </div>
              </div>
            </div>
            <div className="row">
              {this.props.services
                ? this.props.services.map((service) => {
                    return (
                      <div key={service.title} className="col-md-4 col-sm-6">
                        <div className="service-box">
                          <div className="service-icon yellow">
                            <div className="front-content">
                              <i
                                className={`fa ${service.icon}`}
                                aria-hidden="true"
                              ></i>
                              <h3>{service.title}</h3>
                            </div>
                          </div>
                          <a
                            style={{ display: "block", cursor: "pointer" }}
                            // onClick={() =>
                            //   this.handleOnClick(
                            //     service.title,
                            //     service.description
                            //   )
                            // }
                          >
                            <div className="service-content">
                              <h3>{service.title}</h3>
                            </div>
                          </a>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
            <div className="row text-center">
              <p className="myquote">
                {this.state.servicesText["service-bottom-title"]}
              </p>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-6">
                <div className="lead text-md-left">
                  {this.state.servicesText["service1"]}
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="msNote">
                  <h2>{this.state.servicesText["service1-h2"]}</h2>

                  <p>{this.state.servicesText["service1-p"]}</p>
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="msNote2">
                  <h2>{this.state.servicesText["service2-h2"]}</h2>
                  <p>{this.state.servicesText["service2-p"]}</p>
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="lead text-md-left">
                  {this.state.servicesText["service2"]}
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="lead text-md-left">
                  {this.state.servicesText["service3"]}
                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="msNote">
                  <p>{this.state.servicesText["service3-p"]}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Gmodel
          show={this.state.showmodel}
          handleOnhide={this.handleOnhide}
          title={this.state.title}
          description={this.state.description}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  services: state.services,
});

const mapDispatchToProps = { fetchServices };

export default connect(mapStateToProps, mapDispatchToProps)(Services);
