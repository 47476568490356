import React from "react";
import { Modal } from "react-bootstrap";
const Gmodel = ({ show, handleOnhide, title, description }) => {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleOnhide}
      aria-labelledby="example-modal-sizes-title-lg"
      className="gmodel"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{description}</Modal.Body>
    </Modal>
  );
};

export default Gmodel;
