import React from "react";
import Header from "../Header";
import { Redirect } from "react-router-dom";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { fetchReleases, deleteRelease } from "../../../store/actions";
import { RELEASE_IMAGE_URL } from "../../../store/constants";
const imgUrl = RELEASE_IMAGE_URL;
class ReleasesList extends React.Component {
  state = { redirect: false };
  componentDidMount() {
    this.props.fetchReleases();
  }
  async delete(id) {
    await this.props.deleteRelease(id, this.props.token);
    this.props.fetchReleases();
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <div>
        <Header />
        <Container className="mt-3">
          <Row className="mb-3">
            <Col sm={6} className="text-left">
              <h4>List of releases</h4>
            </Col>
            <Col sm={6} className="text-right">
              <Button
                onClick={() =>
                  this.setState({ redirect: "/admin/releases/new" })
                }
              >
                Add Release
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Album title</th>
                    <th>Album desc</th>
                    <th>Image</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.releases.map((release) => {
                    return (
                      <tr key={release.id}>
                        <td>{release.id}</td>
                        <td>{release.albumtitle}</td>
                        <td>{release.albumdesc}</td>
                        <td>
                          <img
                            src={imgUrl + release.image}
                            width="40"
                            height="40"
                            alt={release.albumtitle}
                          />
                        </td>
                        <td>
                          <Button
                            variant="danger"
                            onClick={() => this.delete(release.id)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  token: state.access_token,
  releases: state.releases,
});
const mapDispatchToProps = { fetchReleases, deleteRelease };
export default connect(mapStateToProps, mapDispatchToProps)(ReleasesList);
