import {
  USER_AUTH,
  API_REQUEST,
  RESPONSE_ARTISTS,
  RESPONSE_RELEASES,
  API_ERROR,
  RESPONSE_STATUS,
  RESPONSE_MESSAGE,
  RESPONSE_DISTRIBUTIONS,
  RESPONSE_SERVICES,
  RESET_TOASTER,
  LOGIN_URL,
  ARTISTS_URL,
  SERVICES_URL,
  DISTRIBUTIONS_URL,
  SENDEMAIL_URL,
  RELEASES_URL,
} from "../constants";
import Swal from "sweetalert2";

export const loginUser = (payload) => ({
  type: USER_AUTH,
  payload,
});

export const apiRequest = (payload) => ({
  type: API_REQUEST,
  payload,
});
export const recieveServices = (payload) => ({
  type: RESPONSE_SERVICES,
  payload,
});

export const recieveDistributions = (payload) => ({
  type: RESPONSE_DISTRIBUTIONS,
  payload,
});

export const recieveReleases = (payload) => ({
  type: RESPONSE_RELEASES,
  payload,
});

export const recieveArtists = (payload) => ({
  type: RESPONSE_ARTISTS,
  payload,
});

export const apiError = (payload) => ({
  type: API_ERROR,
  payload,
});

export const responseStatus = (payload) => ({
  type: RESPONSE_STATUS,
  payload,
});

export const responseMessage = (payload) => ({
  type: RESPONSE_MESSAGE,
  payload,
});

export const resetToaster = (payload) => ({
  type: RESET_TOASTER,
  payload,
});

export function makeUserLogin(data) {
  return function (dispatch) {
    dispatch(apiRequest(true));
    return fetch(LOGIN_URL, {
      mode: "cors",
      credentials: "omit",
      referrerPolicy: "no-referrer",
      method: "POST",
      body: data,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(apiError(error))
      )
      .then((response) => {
        if (response.token) {
          dispatch(loginUser(response.token));
        }
        dispatch(responseStatus(response.status));
        dispatch(responseMessage(response.message));
      });
  };
}

export function fetchArtists() {
  return function (dispatch) {
    dispatch(apiRequest(true));
    return fetch(ARTISTS_URL, {
      mode: "cors",
      credentials: "omit",
      referrerPolicy: "no-referrer",
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(apiError(error))
      )
      .then((response) => {
        dispatch(recieveArtists(response));
      });
  };
}

export function insertArtist(data, token) {
  return function (dispatch) {
    dispatch(apiRequest(true));
    return fetch(ARTISTS_URL, {
      mode: "cors",
      credentials: "omit",
      referrerPolicy: "no-referrer",
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: data,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(apiError(error))
      )
      .then((response) => {
        dispatch(responseStatus(response.status));
        dispatch(responseMessage(response.message));
      });
  };
}

export function updateArtist(id, data, token) {
  return function (dispatch) {
    dispatch(apiRequest(true));
    return fetch(ARTISTS_URL + `${id}`, {
      mode: "cors",
      credentials: "omit",
      referrerPolicy: "no-referrer",
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: data,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(apiError(error))
      )
      .then((response) => {
        dispatch(responseStatus(response.status));
        dispatch(responseMessage(response.message));
      });
  };
}

export function deleteArtist(id, token) {
  return function (dispatch) {
    dispatch(apiRequest(true));
    return fetch(ARTISTS_URL + id, {
      mode: "cors",
      credentials: "omit",
      referrerPolicy: "no-referrer",
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(apiError(error))
      )
      .then((response) => {
        dispatch(responseStatus(response.status));
        dispatch(responseMessage(response.message));
      });
  };
}

export function fetchReleases() {
  return function (dispatch) {
    dispatch(apiRequest(true));
    return fetch(RELEASES_URL, {
      mode: "cors",
      credentials: "omit",
      referrerPolicy: "no-referrer",
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(apiError(error))
      )
      .then((response) => {
        dispatch(recieveReleases(response));
      });
  };
}

export function insertRelease(data, token) {
  return function (dispatch) {
    dispatch(apiRequest(true));
    return fetch(RELEASES_URL, {
      mode: "cors",
      credentials: "omit",
      referrerPolicy: "no-referrer",
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: data,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(apiError(error))
      )
      .then((response) => {
        dispatch(responseStatus(response.status));
        dispatch(responseMessage(response.message));
      });
  };
}

export function deleteRelease(id, token) {
  return function (dispatch) {
    dispatch(apiRequest(true));
    return fetch(RELEASES_URL + id, {
      mode: "cors",
      credentials: "omit",
      referrerPolicy: "no-referrer",
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(apiError(error))
      )
      .then((response) => {
        dispatch(responseStatus(response.status));
        dispatch(responseMessage(response.message));
      });
  };
}

export function fetchDistributions() {
  return function (dispatch) {
    dispatch(apiRequest(true));
    return fetch(DISTRIBUTIONS_URL, {
      mode: "cors",
      credentials: "omit",
      referrerPolicy: "no-referrer",
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(apiError(error))
      )
      .then((response) => {
        dispatch(recieveDistributions(response));
      });
  };
}

export function insertDistribution(data, token) {
  return function (dispatch) {
    dispatch(apiRequest(true));
    return fetch(DISTRIBUTIONS_URL, {
      mode: "cors",
      credentials: "omit",
      referrerPolicy: "no-referrer",
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: data,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(apiError(error))
      )
      .then((response) => {
        dispatch(responseStatus(response.status));
        dispatch(responseMessage(response.message));
      });
  };
}

export function deleteDistribution(id, token) {
  return function (dispatch) {
    dispatch(apiRequest(true));
    return fetch(DISTRIBUTIONS_URL + id, {
      mode: "cors",
      credentials: "omit",
      referrerPolicy: "no-referrer",
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(apiError(error))
      )
      .then((response) => {
        dispatch(responseStatus(response.status));
        dispatch(responseMessage(response.message));
      });
  };
}
export function fetchServices() {
  return function (dispatch) {
    dispatch(apiRequest(true));
    return fetch(SERVICES_URL, {
      mode: "cors",
      credentials: "omit",
      referrerPolicy: "no-referrer",
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(apiError(error))
      )
      .then((response) => {
        dispatch(recieveServices(response));
      });
  };
}

export function insertService(data, token) {
  return function (dispatch) {
    dispatch(apiRequest(true));
    return fetch(SERVICES_URL, {
      mode: "cors",
      credentials: "omit",
      referrerPolicy: "no-referrer",
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: data,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(apiError(error))
      )
      .then((response) => {
        dispatch(responseStatus(response.status));
        dispatch(responseMessage(response.message));
      });
  };
}

export function updateService(id, data, token) {
  return function (dispatch) {
    dispatch(apiRequest(true));
    return fetch(SERVICES_URL + `${id}`, {
      mode: "cors",
      credentials: "omit",
      referrerPolicy: "no-referrer",
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
      },
      body: data,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(apiError(error))
      )
      .then((response) => {
        dispatch(responseStatus(response.status));
        dispatch(responseMessage(response.message));
      });
  };
}

export function deleteService(id, token) {
  return function (dispatch) {
    dispatch(apiRequest(true));
    return fetch(SERVICES_URL + id, {
      mode: "cors",
      credentials: "omit",
      referrerPolicy: "no-referrer",
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(apiError(error))
      )
      .then((response) => {
        dispatch(responseStatus(response.status));
        dispatch(responseMessage(response.message));
      });
  };
}

export function sendEmail(data) {
  return function (dispatch) {
    dispatch(apiRequest(true));
    return fetch(SENDEMAIL_URL, {
      mode: "cors",
      credentials: "omit",
      referrerPolicy: "no-referrer",
      method: "POST",
      body: data,
    })
      .then(
        (response) => response.json(),
        (error) => dispatch(apiError(error))
      )
      .then((response) => {
        Swal.fire({
          title: response.message,
          showConfirmButton: false,
          timer: 2000,
        });
        dispatch(responseStatus(response.status));
        dispatch(responseMessage(response.message));
      });
  };
}
