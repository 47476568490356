import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { resetToaster } from "../../store/actions";
const customId = "custom-id-yes";
class Header extends React.Component {
  render() {
    if (this.props.status && this.props.message) {
      if (this.props.status == "success") {
        toast.success(this.props.message, {
          onClose: () => this.props.resetToaster(),
          toastId: customId,
        });
      } else {
        toast.error(this.props.message, {
          onClose: () => this.props.resetToaster(),
        });
      }
    }
    return (
      <>
        <div
          class="loader"
          style={{ display: this.props.loading ? "inline-block" : "none" }}
        >
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Link className="navbar-brand" to="/admin/artists">
            TopDot
          </Link>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Link className="nav-link" to="/admin/artists">
                Artists
              </Link>
              <Link className="nav-link" to="/admin/releases">
                Releases
              </Link>
              <Link className="nav-link" to="/admin/distributions">
                Distributions
              </Link>
              <Link className="nav-link" to="/admin/services">
                Services
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  status: state.status,
  message: state.message,
  loading: state.loading,
});

const mapDispatchToProps = { resetToaster };
export default connect(mapStateToProps, mapDispatchToProps)(Header);
