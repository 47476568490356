import React, { useState, useEffect } from "react";

const About = () => {
  const [aboutText, setAboutText] = useState("");
  useEffect(() => {
    fetch("client/about.json")
      .then((response) => response.json())
      .then((data) => setAboutText(data));
  }, []);
  return (
    <section id="About" className="content-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="block-heading">
              <h1>About Us</h1>
            </div>
            <p className="lead">{aboutText.text}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
