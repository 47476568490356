import React from "react";
import { connect } from "react-redux";
import { fetchReleases } from "../../store/actions";
import { slice, concat } from "lodash";
import { RELEASE_IMAGE_URL } from "../../store/constants";

const imgUrl = RELEASE_IMAGE_URL;

class Releases extends React.Component {
  constructor(props) {
    super(props);
    this.limit = 3;
    this.data = [];
    this.state = {
      showMore: true,
      list: [],
      index: this.limit,
    };
  }

  loadMoresetup = () => {
    this.data = this.props.releases ? this.props.releases : null;
    this.setState({
      list: slice(this.data, 0, this.state.index),
      showMore: this.limit < this.data.length,
    });
  };

  componentDidMount() {
    this.fetchReleases();
  }

  async fetchReleases() {
    await this.props.fetchReleases();
    this.loadMoresetup();
  }

  loadMore = () => {
    const newIndex = this.state.index + this.limit;
    const newShowMore = newIndex < this.data.length;
    const newList = concat(
      this.state.list,
      slice(this.data, this.state.index, newIndex)
    );
    this.setState({
      index: newIndex,
      list: newList,
      showMore: newShowMore,
    });
  };
  render() {
    return (
      <section className="content-section text-center" id="Portfolio">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="block-heading">
                <h1>New Releases</h1>
              </div>
            </div>
          </div>
          <div className="portfolio-wrapper clearfix">
            {this.state.list
              ? this.state.list.map((release) => {
                  return (
                    <a
                      key={release.id}
                      className="each-portfolio"
                      data-fancybox="gallery"
                      href={imgUrl + release.image}
                    >
                      <div className="content hover-cont-wrap">
                        <div className="content-overlay"></div>
                        <img
                          className="content-image"
                          src={imgUrl + release.image}
                        />
                        <div className="content-details fadeIn-bottom">
                          <h5 className="p-title">{release.albumtitle}</h5>
                          <p className="p-desc d-none">{release.albumdesc}</p>
                          <span className="zoom">
                            <i className="fa fa-search-plus"></i>
                          </span>
                        </div>
                      </div>
                    </a>
                  );
                })
              : null}
            {this.state.showMore && (
              <button className="btn btn-light btn-lg" onClick={this.loadMore}>
                {" "}
                Load More{" "}
              </button>
            )}
          </div>
        </div>
      </section>
    );
  }
}
const mapStateToProps = (state) => ({
  releases: state.releases,
});
const mapDispatchToProps = { fetchReleases };
export default connect(mapStateToProps, mapDispatchToProps)(Releases);
