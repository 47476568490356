import React from "react";
import Header from "../Header";
import Banner from "../Banner";
import Artists from "../Artists";
import Distribution from "../Distribution";
import Releases from "../Releases";
import Services from "../Services";
import Publishing from "../Publishing";
import News from "../News";
import About from "../About";
import Contact from "../Contact";
import Footer from "../Footer";

class Public extends React.Component {
  render() {
    return (
      <>
        <a href="#page-top" id="myTopBtn">
          <i className="fa fa-arrow-up"></i>
        </a>
        <Header />
        <Banner />
        <Artists />
        <Distribution />
        <Releases />
        <Services />
        <Publishing />
        <News />
        <About />
        <Contact />
        <Footer />
      </>
    );
  }
}

export default Public;
