import React from "react";
import Header from "../Header";
import { Redirect } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import { insertService, fetchServices } from "../../../store/actions";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Select from "react-select";
import faList from "../../../utils/font-awesome.json";
import { forEach } from "lodash";

const schema = yup.object({
  title: yup.string().required(),
  description: yup.string().required(),
  icon: yup.string().required(),
});

class ServicesNew extends React.Component {
  state = { redirect: false };
  onFormSubmit = (values) => {
    this.insertService(values);
    this.setState({ redirect: "/admin/services" });
  };

  async insertService(values) {
    const formdata = new FormData();
    for (var key in values) {
      formdata.append(key, values[key]);
    }
    await this.props.insertService(formdata, this.props.token);
    this.props.fetchServices();
  }

  formatOptionLabel = ({ value, label }) => (
    <div style={{ display: "flex" }}>
      <div style={{ marginRight: "10px" }}>
        <i class={`fa ${value}`} />
      </div>
      <div>{label}</div>
    </div>
  );

  render() {
    const optionsRaw = faList["4.7.0"];
    let options = [];
    forEach(optionsRaw, (item) => {
      options.push({ value: item, label: item });
    });
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <div>
        <Header />
        <Container className="mt-3">
          <Row className="mb-3">
            <Col sm={6} className="text-left">
              <h4>Add new service</h4>
            </Col>
            <Col sm={6} className="text-right">
              <Button
                variant="light"
                onClick={() => this.setState({ redirect: "/admin/services" })}
              >
                Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Formik
                validateOnChange={false}
                validationSchema={schema}
                onSubmit={this.onFormSubmit}
                initialValues={{
                  title: "",
                  description: "",
                  icon: "",
                }}
              >
                {({ handleSubmit, handleChange, values, errors }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik101"
                      >
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          type="text"
                          name="title"
                          value={values.title}
                          onChange={handleChange}
                          isInvalid={!!errors.title}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.title}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>

                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik106"
                      >
                        <Form.Label>Describe service</Form.Label>
                        <textarea
                          className="form-control"
                          type="text"
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                          isInvalid={!!errors.description}
                        >
                          {values.description}
                        </textarea>
                        {errors.description ? (
                          <div
                            class="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors.description}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group
                        as={Col}
                        md="4"
                        controlId="validationFormik101"
                      >
                        <Form.Label>Icon</Form.Label>
                        <Select
                          isSearchable
                          name="icon"
                          options={options}
                          onChange={(selectedOption) => {
                            values.icon = selectedOption.value;
                            handleChange("icon");
                          }}
                          formatOptionLabel={this.formatOptionLabel}
                        />
                        {/* <Form.Control
                          type="text"
                          name="icon"
                          value={values.icon}
                          onChange={handleChange}
                          isInvalid={!!errors.icon}
                        /> */}
                        {errors.icon ? (
                          <div
                            class="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {errors.icon}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Form.Row>
                    <Button type="submit">Submit form</Button>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  token: state.access_token,
});
const mapDispatchToProps = { insertService, fetchServices };
export default connect(mapStateToProps, mapDispatchToProps)(ServicesNew);
